import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=DatePicker wrap=function",
        "componentName": "DatePicker",
        "wrap": "function"
      }}>{`() => {
  const [date, setDate] = React.useState(new Date());

  return (
    <DatePicker
      id="top"
      label="Pick your date"
      onChange={date => setDate(date)}
      value={date}
    />
  );
};
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="DatePicker" mdxType="Props" />
    <h2 {...{
      "id": "onchange",
      "style": {
        "position": "relative"
      }
    }}>{`OnChange`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p><inlineCode parentName="p">{`DatePicker`}</inlineCode>{`'s `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` function is slightly different than that from
`}<inlineCode parentName="p">{`TextField`}</inlineCode>{`. Instead of returning the input field's value, we return 2
arguments.`}</p>
    <ul>
      <li parentName="ul">{`The first argument is the JavaScript Date object of the currently selected
date. However, this value can be `}<inlineCode parentName="li">{`false`}</inlineCode>{` in two cases: if a user manually
inputs a date that is either not yet complete (e.g. `}<inlineCode parentName="li">{`02/04/202_`}</inlineCode>{`) or inputs an
invalid date (e.g. `}<inlineCode parentName="li">{`30/02/2020`}</inlineCode>{`).`}</li>
      <li parentName="ul">{`The second argument is an object that contains more information about the
selected date. The current properties of this object are:`}<ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`disabled`}</inlineCode>{`: This will be true if the currently selected date is inside your
range of `}<inlineCode parentName="li">{`disabledDays`}</inlineCode>{` (see below).`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`formattedDate`}</inlineCode>{`: This is a string representation of the currently selected
date, formatted by the `}<inlineCode parentName="li">{`format`}</inlineCode>{` prop.`}</li>
        </ul></li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=DatePicker wrap=function",
        "componentName": "DatePicker",
        "wrap": "function"
      }}>{`() => {
  const [date, setDate] = React.useState(new Date());

  return (
    <DatePicker
      id="onchange"
      label="Pick your date"
      onChange={(date, properties) => {
        alert(\`
          JavaScript date: \${date}
          Disabled: \${properties.disabled}
          FormattedDate: \${properties.formattedDate}
        \`);
        setDate(date);
      }}
      value={date}
    />
  );
};
`}</code></pre>
    <h2 {...{
      "id": "disableddays",
      "style": {
        "position": "relative"
      }
    }}>{`DisabledDays`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`To prevent a user from selecting certain dates, you can supply the
`}<inlineCode parentName="p">{`disabledDays`}</inlineCode>{` prop. This prop can be either:`}</p>
    <ul>
      <li parentName="ul">{`A `}<inlineCode parentName="li">{`Date`}</inlineCode>{` object. This will disable the specific day.`}</li>
      <li parentName="ul">{`A range object with `}<inlineCode parentName="li">{`from`}</inlineCode>{` and `}<inlineCode parentName="li">{`to`}</inlineCode>{` keys to match a range of days.`}</li>
      <li parentName="ul">{`An object with a `}<inlineCode parentName="li">{`before`}</inlineCode>{` and/or `}<inlineCode parentName="li">{`after`}</inlineCode>{` key to match the days before and/or
after the given date.`}</li>
      <li parentName="ul">{`An object with a `}<inlineCode parentName="li">{`daysOfWeek`}</inlineCode>{` array to match specific days of week.`}</li>
      <li parentName="ul">{`A function taking the day as first argument and `}<inlineCode parentName="li">{`returning a boolean value`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`An array of the above.`}</li>
    </ul>
    <h4 {...{
      "id": "date-object",
      "style": {
        "position": "relative"
      }
    }}>{`Date object`}</h4>
    <p>{`This example disables a single day.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=DatePicker wrap=function",
        "componentName": "DatePicker",
        "wrap": "function"
      }}>{`() => {
  const [date, setDate] = React.useState(new Date(2020, 3, 19));

  return (
    <DatePicker
      id="dateobject"
      label="Pick your date"
      onChange={setDate}
      value={date}
      disabledDays={new Date(2020, 3, 20)}
    />
  );
};
`}</code></pre>
    <h4 {...{
      "id": "range-object",
      "style": {
        "position": "relative"
      }
    }}>{`Range object`}</h4>
    <p>{`In this example, a range, described by `}<inlineCode parentName="p">{`from`}</inlineCode>{` and `}<inlineCode parentName="p">{`to`}</inlineCode>{` keys, is disabled.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=DatePicker wrap=function",
        "componentName": "DatePicker",
        "wrap": "function"
      }}>{`() => {
  const [date, setDate] = React.useState(new Date(2020, 2, 14));

  return (
    <DatePicker
      id="range"
      label="Pick your date"
      onChange={setDate}
      value={date}
      disabledDays={{ from: new Date(2020, 2, 21), to: new Date(2020, 2, 30) }}
    />
  );
};
`}</code></pre>
    <h4 {...{
      "id": "before",
      "style": {
        "position": "relative"
      }
    }}>{`Before`}</h4>
    <p>{`When providing an object with a `}<inlineCode parentName="p">{`before`}</inlineCode>{` key, all dates before that date (not
including the date itself) are disabled.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=DatePicker wrap=function",
        "componentName": "DatePicker",
        "wrap": "function"
      }}>{`() => {
  const [date, setDate] = React.useState(new Date(2020, 2, 28));

  return (
    <DatePicker
      id="before"
      label="Pick your date"
      onChange={setDate}
      value={date}
      disabledDays={{ before: new Date(2020, 2, 21) }}
    />
  );
};
`}</code></pre>
    <h4 {...{
      "id": "after",
      "style": {
        "position": "relative"
      }
    }}>{`After`}</h4>
    <p>{`When providing an object with an `}<inlineCode parentName="p">{`after`}</inlineCode>{` key, all dates after that date (not
including the date itself) are disabled.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=DatePicker wrap=function",
        "componentName": "DatePicker",
        "wrap": "function"
      }}>{`() => {
  const [date, setDate] = React.useState(new Date(2020, 2, 28));

  return (
    <DatePicker
      id="after"
      label="Pick your date"
      onChange={setDate}
      value={date}
      disabledDays={{ after: new Date(2020, 2, 29) }}
    />
  );
};
`}</code></pre>
    <h4 {...{
      "id": "before-and-after",
      "style": {
        "position": "relative"
      }
    }}>{`Before and after`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`before`}</inlineCode>{` and `}<inlineCode parentName="p">{`after`}</inlineCode>{` keys can be combined to create a range of
"allowedDates". The next example will only allow a single week.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=DatePicker wrap=function",
        "componentName": "DatePicker",
        "wrap": "function"
      }}>{`() => {
  const [date, setDate] = React.useState(new Date(2020, 2, 28));

  return (
    <DatePicker
      id="beforeandafter"
      label="Pick your date"
      onChange={setDate}
      value={date}
      disabledDays={{
        before: new Date(2020, 2, 23),
        after: new Date(2020, 2, 29),
      }}
    />
  );
};
`}</code></pre>
    <h4 {...{
      "id": "days-of-week",
      "style": {
        "position": "relative"
      }
    }}>{`Days of week`}</h4>
    <p>{`An array of numbers corresponding to days to be disabled (Note: starting on
Sunday and zero-index, so Sunday is 0). The example below disabled every Friday
and every Sunday.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=DatePicker wrap=function",
        "componentName": "DatePicker",
        "wrap": "function"
      }}>{`() => {
  const [date, setDate] = React.useState(new Date(2020, 2, 28));

  return (
    <DatePicker
      id="daysofweek"
      label="Pick your date"
      onChange={setDate}
      value={date}
      inline
      disabledDays={{
        daysOfWeek: [0, 5],
      }}
    />
  );
};
`}</code></pre>
    <h4 {...{
      "id": "function",
      "style": {
        "position": "relative"
      }
    }}>{`Function`}</h4>
    <p>{`The function takes the day as the first argument. If the function returns
`}<inlineCode parentName="p">{`true`}</inlineCode>{`, the day is disabled. The example below disables every day that is not
even.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=DatePicker wrap=function",
        "componentName": "DatePicker",
        "wrap": "function"
      }}>{`() => {
  const [date, setDate] = React.useState(new Date(2020, 2, 28));

  return (
    <DatePicker
      id="function"
      label="Pick your date"
      onChange={setDate}
      value={date}
      disabledDays={day => {
        return day.getDate() % 2;
      }}
    />
  );
};
`}</code></pre>
    <h4 {...{
      "id": "array",
      "style": {
        "position": "relative"
      }
    }}>{`Array`}</h4>
    <p>{`An `}<inlineCode parentName="p">{`array`}</inlineCode>{` can be used to combine any number of the above options. The example
below disables all days except a single week, and in that week, Wednesday,
Saturday and Sunday are disabled.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=DatePicker wrap=function",
        "componentName": "DatePicker",
        "wrap": "function"
      }}>{`() => {
  const [date, setDate] = React.useState(new Date(2020, 2, 26));

  return (
    <DatePicker
      id="array"
      label="Pick your date"
      onChange={setDate}
      value={date}
      disabledDays={[
        {
          before: new Date(2020, 2, 23),
          after: new Date(2020, 2, 29),
        },
        { daysOfWeek: [0, 6, 3] },
      ]}
    />
  );
};
`}</code></pre>
    <h2 {...{
      "id": "mask--format",
      "style": {
        "position": "relative"
      }
    }}>{`Mask & format`}</h2>
    <p><inlineCode parentName="p">{`mask`}</inlineCode>{` `}<inlineCode parentName="p">{`default: 99/99/99`}</inlineCode></p>
    <p><inlineCode parentName="p">{`format`}</inlineCode>{` `}<inlineCode parentName="p">{`default: DD/MM/YYY`}</inlineCode></p>
    <p>{`To visually aid the user with the expected format, you can use an input mask.
Input masks will display a template of the expected value. The mask is built out
of several definitions:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`9`}</inlineCode>{`: numeric`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`a`}</inlineCode>{`: alphabetical`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`*`}</inlineCode>{`: alphanumeric`}</li>
    </ul>
    <p>{`Linked to the input mask, is the format of the date. The available tokens for
parsing date are:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`YY`}</inlineCode>{` Two-digit year (e.g. 18)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`YYYY`}</inlineCode>{` Four-digit year (e.g. 2018)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`M`}</inlineCode>{` Month, beginning at 1 (e.g. 1-12)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`MM`}</inlineCode>{` Month, 2-digits (e.g. 01-12)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`MMM`}</inlineCode>{` The abbreviated month name (e.g. Jan-Dec)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`MMMM`}</inlineCode>{` The full month name (e.g. January-December)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`D`}</inlineCode>{` Day of month (e.g. 1-31)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`DD`}</inlineCode>{` Day of month, 2-digits (e.g. 01-31)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`H`}</inlineCode>{` Hours (e.g. 0-23)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`HH`}</inlineCode>{` Hours, 2-digits (e.g. 00-23)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`h`}</inlineCode>{` Hours, 12-hour clock (e.g. 1-12)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`hh`}</inlineCode>{` Hours, 12-hour clock, 2-digits (e.g. 01-12)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`m`}</inlineCode>{` Minutes (e.g. 0-59)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`mm`}</inlineCode>{` Minutes, 2-digits (e.g. 00-59)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`s`}</inlineCode>{` Seconds (e.g. 0-59)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ss`}</inlineCode>{` Seconds, 2-digits (e.g. 00-59)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`S`}</inlineCode>{` Hundreds of milliseconds, 1-digit (e.g. 0-9)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`SS`}</inlineCode>{` Tens of milliseconds, 2-digits (e.g. 00-99)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`SSS`}</inlineCode>{` Milliseconds, 3-digits (e.g. 000-999)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Z`}</inlineCode>{` Offset from UTC (e.g. -05:00)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ZZ`}</inlineCode>{` Compact offset from UTC, 2-digits (e.g. -0500)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`A`}</inlineCode>{` Post or ante meridiem, upper-case (e.g. AM PM)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`a`}</inlineCode>{` Post or ante meridiem, lower-case (e.g. am pm)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Do`}</inlineCode>{` Day of Month with ordinal (e.g. 1st... 31st)`}</li>
    </ul>
    <p><strong parentName="p">{`Note:`}</strong>{` It is important that if you change one of these values, that the other
value is still compliant. Setting a mask of `}<inlineCode parentName="p">{`99-aaa`}</inlineCode>{` won't make much sense if
your `}<inlineCode parentName="p">{`format`}</inlineCode>{` is still on default `}<inlineCode parentName="p">{`DD/MM/YYYY`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=DatePicker wrap=function",
        "componentName": "DatePicker",
        "wrap": "function"
      }}>{`() => {
  const [date, setDate] = React.useState(new Date(2020, 2, 26));

  return (
    <DatePicker
      id="mask"
      label="Pick your date"
      mask="99-aaa"
      format="DD-MMM"
      onChange={setDate}
      value={date}
    />
  );
};
`}</code></pre>
    <h2 {...{
      "id": "localization",
      "style": {
        "position": "relative"
      }
    }}>{`Localization`}</h2>
    <p>{`DatePicker has localization built in. This built-in localization may be
different between brands, but this is logic that is provided by Chameleon so
that you as a developer don't have to worry about it.`}</p>
    <p>{`In the example below, you can see the localization by properties such as the
first day of the week, the short names of the days, ...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=DatePicker wrap=function",
        "componentName": "DatePicker",
        "wrap": "function"
      }}>{`() => {
  const [date, setDate] = React.useState(new Date(2020, 2, 26));

  return (
    <>
      <DatePicker
        id="localization"
        label="Pick your date"
        onChange={setDate}
        value={date}
      />
    </>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "error",
      "style": {
        "position": "relative"
      }
    }}>{`Error`}</h2>
    <p><inlineCode parentName="p">{`default: false`}</inlineCode></p>
    <p>{`To convey an error to the user, you can set the `}<inlineCode parentName="p">{`error`}</inlineCode>{` prop to true. This can
come in handy to indicate an invalid date, or if the user would type in a date
that would be outside of your date range.`}</p>
    <p>{`In the example below, try removing a character or set the date to an impossible
date (e.g. 30/02/2020).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=DatePicker wrap=function",
        "componentName": "DatePicker",
        "wrap": "function"
      }}>{`() => {
  const [date, setDate] = React.useState(new Date(2020, 2, 26));

  return (
    <>
      <DatePicker
        id="error"
        label="Pick your date"
        onChange={setDate}
        value={date}
        error={date === false}
        message={date ? 'When were you born?' : 'Invalid date selected!'}
      />
    </>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "inline",
      "style": {
        "position": "relative"
      }
    }}>{`Inline`}</h2>
    <p><inlineCode parentName="p">{`default: false`}</inlineCode></p>
    <p>{`By default, the calendar will be closed and only opens when clicking the
calender icon. You can have the calendar open by default and act as an inline
element by passing in the `}<inlineCode parentName="p">{`inline`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=DatePicker wrap=function",
        "componentName": "DatePicker",
        "wrap": "function"
      }}>{`() => {
  const [date, setDate] = React.useState(new Date(2020, 2, 26));

  return (
    <>
      <DatePicker
        id="inline"
        label="Pick your date"
        inline
        onChange={setDate}
        value={date}
      />
    </>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "disabled",
      "style": {
        "position": "relative"
      }
    }}>{`Disabled`}</h2>
    <p><inlineCode parentName="p">{`default: false`}</inlineCode></p>
    <p>{`Marking a DatePicker as disabled, will disable all interaction with the element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=DatePicker wrap=function",
        "componentName": "DatePicker",
        "wrap": "function"
      }}>{`() => {
  const [date, setDate] = React.useState(new Date(2020, 2, 26));

  return (
    <DatePicker
      id="disabled"
      label="Do not pick your date"
      disabled
      onChange={setDate}
      value={date}
    />
  );
};
`}</code></pre>
    <h2 {...{
      "id": "optionallabel-required-fields",
      "style": {
        "position": "relative"
      }
    }}>{`OptionalLabel (Required fields)`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`By default the DatePicker is always `}<strong parentName="p">{`required`}</strong>{`. To make a DatePicker optional
you can add the `}<inlineCode parentName="p">{`optionalLabel`}</inlineCode>{` prop.`}</p>
    <p>{`With Chameleon we take a specific approach where we encourage marking optional
fields, not required. The majority of fields in a form are always required.
Indicating that fields are required make users more fearful, it increases the
risk of errors and reduces the form completion rate.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=DatePicker wrap=function",
        "componentName": "DatePicker",
        "wrap": "function"
      }}>{`() => {
  const [date, setDate] = React.useState(new Date(2020, 2, 26));

  return (
    <DatePicker
      id="label"
      label="Pick your date"
      optionalLabel="Optional"
      onChange={setDate}
      value={date}
    />
  );
};
`}</code></pre>
    <h2 {...{
      "id": "pickerinputonly",
      "style": {
        "position": "relative"
      }
    }}>{`PickerInputOnly`}</h2>
    <p><inlineCode parentName="p">{`default: false`}</inlineCode></p>
    <p>{`If you still want full functionality of the DatePicker, but don't want users to
be able to manually enter a date, you can pass in the `}<inlineCode parentName="p">{`pickerInputOnly`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=DatePicker wrap=function",
        "componentName": "DatePicker",
        "wrap": "function"
      }}>{`() => {
  const [date, setDate] = React.useState(new Date(2020, 2, 26));

  return (
    <DatePicker
      id="disabled"
      label="Pick your date"
      pickerInputOnly
      onChange={setDate}
      value={date}
    />
  );
};
`}</code></pre>
    <h2 {...{
      "id": "label-props",
      "style": {
        "position": "relative"
      }
    }}>{`Label Props`}</h2>
    <p>{`As with other input elements, `}<inlineCode parentName="p">{`DatePicker`}</inlineCode>{` also has a wide range of extra
properties to convey even more meaning.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=DatePicker wrap=function",
        "componentName": "DatePicker",
        "wrap": "function"
      }}>{`() => {
  const [date, setDate] = React.useState(new Date(2020, 2, 26));

  return (
    <DatePicker
      id="label"
      label="Pick your date"
      action={<LinkText>What dates are valid?</LinkText>}
      message="Go Charlie, it's your birthday"
      optionalLabel="Not needed"
      onChange={setDate}
      value={date}
    />
  );
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      